<template>
	<b-modal id="add-user" size="lg" title="Add User" ref="modal" ok-title="Add" @ok="handleOk" @show="onReset"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" 
		:no-close-on-backdrop="true" hide-header-close>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<div>
					<b-tabs>
						<b-tab title="Primary Information" active>
							<b-row class="my-2">
								<b-col lg="6" md="12" sm="12">
									<b-form-group label="Company:">
										<v-select name="Company" class="style-chooser" label="text" placeholder=" - Please select - "
											:options="companyOptions" :reduce="(company) => company.value"
											v-model="selCompany" v-validate="'selectRequired'">
											<template v-slot:no-options="{ search, searching }">
												<template v-if="searching">
													No results found for
													<em>
														<strong>{{ search }}</strong>
													</em>
												</template>
												<em :style="{ opacity: 0.5 }" v-else>
													Start typing to search for a company
												</em>
											</template>
										</v-select>
										<span v-show="errors.has('Company')" class="help-block">{{
											errors.first('Company')
										}}</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-2">
								<b-col lg="4" md="12" sm="12">
									<b-form-group label="First Name:" label-for="firstName" description>
										<b-form-input name="First Name" type="text" v-model="form.firstName" v-validate="{
											required: true,
											regex: nameRegex,
										}" placeholder="First Name" maxlength="25" />
										<span v-show="errors.has('First Name')" class="help-block">{{
											errors.first('First Name')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="12" sm="12">
									<b-form-group label="Middle Name:" label-for="middleName" description>
										<b-form-input name="Middle Name" type="text" v-model="form.middleName"
											v-validate="{
												required: false,
												regex: nameRegex,
											}" placeholder="Middle Name" maxlength="25" />
										<span v-show="errors.has('Middle Name')" class="help-block">{{
											errors.first('Middle Name')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="12" sm="12">
									<b-form-group label="Last Name:" label-for="lastName" description>
										<b-form-input name="Last Name" type="text" v-model="form.lastName" v-validate="{
											required: true,
											regex: nameRegex,
										}" placeholder="Last Name" maxlength="25" />
										<span v-show="errors.has('Last Name')" class="help-block">{{
											errors.first('Last Name')
										}}</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-2">
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Job Position:" label-for="position" description>
										<b-form-input name="Position" type="text" v-model="form.position" v-validate="{
											required: true,
											regex: positionRegex,
										}" placeholder="Job Position" maxlength="60" />
										<span v-show="errors.has('Position')" class="help-block">{{
											errors.first('Position')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Employee No.:" label-for="employeeNo" description>
										<b-form-input name="Employee No" type="text" class="numFont"
											v-model="form.employeeNo" v-validate="{
												required: false,
												regex: employeeNoRegex,
											}" placeholder="Employee No" maxlength="12" />
										<span v-show="errors.has('Employee No')" class="help-block">{{
											errors.first('Employee No')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Access Type:" label-for="type" description>
										<b-form-select name="Type" v-model="form.type" v-validate="'required'"
											:options="userTypes" class="mb-3" />
										<span v-show="errors.has('Type')" class="help-block">{{
											errors.first('Type')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Contact No.:" label-for="contactNo" description>
										<b-form-input name="Contact No" type="text" class="numFont"
											v-model="form.contactNo" v-validate="{
												required: contactNoIsRequired,
												regex: contactNoRegex,
											}" placeholder="+639xxxxxxxxx" maxlength="13" autocomplete="off" />
										<span v-show="errors.has('Contact No')" class="help-block">
											{{ 'Contact No. must follow this format: +639xxxxxxxxx' }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Driver's License" v-if="isAccessTypeDriver()">
							<b-row class="my-2">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="License Photo:" label-for="driverId">
										<b-form-file id="drivers-license" placeholder="Choose image"
											ref="dirvers-lincense-file-input" accept="image/png"
											@change="onSelectLicensePhoto($event)">
											<template slot="file-name" slot-scope="{ names }">
												<b-badge variant="dark">{{ names[0] }}</b-badge>
												<b-badge v-if="names.length > 1" variant="dark" class="ml-1">+ {{
													names.length - 1 }} More files</b-badge>
											</template>
										</b-form-file>
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="License No:" label-for="licenseNo"
										:description="'Sample Format: D00-00-000000'">
										<b-form-input name="License No" type="text"
											v-model="form.driversLicense.licenseNo" v-validate="{
												required: true,
												regex: /^[A-Z]{1,2}[0-9]{2}-[0-9]{2}-[0-9]{6}$/,
											}" placeholder="D00-00-000000" maxlength="14" />
										<span v-show="errors.has('License No')" class="help-block">{{
											errors.first('License No')
										}}</span>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row class="my-2">
								<b-col lg="6" md="6" sm="12">
									<!-- Image Preview -->
									<div v-if="licenseMediaObj.url && isAccessTypeDriver()">
										<b-card overlay :img-src="licenseMediaObj.url" img-alt="Card Image"
											text-variant="white" class="mb-0">
											<b-card-text class="text-right">
												<b-button variant="danger" size="sm" @click="onRemoveLicensePhoto()">
													<em class="fa fa-trash"></em>
												</b-button>
											</b-card-text>
										</b-card>
										<b-progress v-if="uploadingState.uploadStatus === 'uploading'"
											:value="uploadingState.percentage" :max="uploadingState.name"
											variant="success" height="8px" animated />
									</div>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Account">
							<b-row class="my-2">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Email Address:" label-for="emailAddress"
										description="Email address will be used as the username of this user's account">
										<b-form-input name="Email Address" type="email" v-model="form.emailAddress"
											v-validate="{
												required: true,
												regex: emailRegex,
											}" placeholder="Email Address" autocomplete="new-email" />
										<span v-show="errors.has('Email Address')" class="help-block">{{
											errors.first('Email Address')
										}}</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-2">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Password" label-for="password">
										<b-input-group>
											<b-form-input name="Password" v-model="form.password" v-validate="{
												required: true,
												regex: passwordRegex,
											}" placeholder="Password" :type="showPassword ? 'text' : 'password'" autocomplete="new-password" />
											<b-input-group-append>
												<b-button @click="showPassword = !showPassword">
													<span>
														<b-icon :icon="showPassword ? 'eye-slash' : 'eye'" />
													</span>
												</b-button>
											</b-input-group-append>
											<span v-show="errors.has('Password')" class="help-block">{{
												passwordErrorMsg
											}}</span>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Confirm Password" label-for="confirmPassword" description>
										<b-input-group>
											<b-form-input name="Confirm Password" v-model="form.confirmPassword"
												v-validate="{
													required: true,
													regex: passwordRegex,
												}" placeholder="Confirm Password" :type="showConfirmPassword ? 'text' : 'password'"
												autocomplete="new-confirm-password" />
											<b-input-group-append>
												<b-button @click="showConfirmPassword = !showConfirmPassword">
													<span>
														<b-icon :icon="showConfirmPassword ? 'eye-slash' : 'eye'" />
													</span>
												</b-button>
											</b-input-group-append>
											<span v-show="errors.has('Confirm Password')" class="help-block">{{
												passwordErrorMsg
											}}</span>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Subscription">
							<b-row class="my-2">
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Status">
										<b-form-radio-group name="Status" v-model="form.isActive" :options="statusOptions" v-validate="'required'"/>
										<span v-show="errors.has('Status')" class="help-block">{{
											errors.first('Status')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Has Notification?">
										<b-form-radio-group name="hasNotification" v-model="form.hasNotif" :options="yesNoOptions" v-validate="'required'"/>
										<span v-show="errors.has('hasNotification')" class="help-block">{{
											'This field is required'
										}}</span>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row class="my-2">
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Enable 2-Way Auth?">
										<b-form-radio-group name="has2WayAuth" v-model="form.has2WayAuth" :options="yesNoOptions" v-validate="'required'"/>
										<span v-show="errors.has('has2WayAuth')" class="help-block">{{
											'This field is required'
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="6" sm="12" v-if="isDriver">
									<b-form-group label="Enable Geofencing?">
										<b-form-radio-group name="enableGeofencing" v-model="form.hasGeofencing" :options="yesNoOptions" v-validate="{ required: isDriver }" />
										<span v-show="errors.has('enableGeofencing')" class="help-block">{{
											'This field is required'
										}}</span>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>
					</b-tabs>
				</div>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Utils
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { FileUtil } from '@/utils/fileUtil';
import { ImageUtil } from '@/utils/imageUtil';
import { UserUtil } from '@/utils/userutil';
import { ValidationUtil } from '@/utils/validationUtil';

// API & DAO
import userApi from '@/api/userApi';
import companyDAO from '@/database/companies';

// Others
import { storage } from '@/config/firebase';
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'add-user',
	components: {
		Loading,
	},
	props: {
		companyOptions: {
			type: Array,
			required: true,
		},
		allCompaniesObj: {
			type: Object,
			required: true,
		},
		allConnectedCompaniesObj: {
			type: Object,
			required: true,
		},
		allConnectionsObj: {
			type: Object,
			required: true,
		},
		allUsersObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			form: { ...UserUtil.getDefaultUserObj() },
			selCompany: { ...config.companyDefaultValue },
			licenseMediaObj: {},
			uploadingState: {},
			currLocation: null,

			userTypes: [],
			yesNoOptions: [
				{ text: 'YES', value: true },
				{ text: 'NO', value: false },
			],
			statusOptions: config.statusOptions,

			showPassword: false,
			showConfirmPassword: false,

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			hasTransportPermission: this.$store.getters.hasTransportPermission,
			hasAssetPoolingPermission: this.$store.getters.hasAssetPoolingPermission,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		contactNoIsRequired() {
			return this.form.has2WayAuth;
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.form.firstName + ' ' + this.form.lastName;
		},
		nameRegex() {
			return config.nameRegex;
		},
		contactNoRegex() {
			return config.contactNoRegex;
		},
		emailRegex() {
			return config.emailRegex;
		},
		positionRegex() {
			return config.positionRegex;
		},
		employeeNoRegex() {
			return config.employeeNoRegex;
		},
		passwordRegex() {
			return config.passwordRegex;
		},
		passwordErrorMsg() {
			return config.passwordErrorMsg;
		},
		isDriver() {
			return this.form.type === 'Driver';
		}
	},
	watch: {
		selCompany: function () {
			let companyId = this.selCompany.id;
			let companyObj = this.allCompaniesObj[companyId];

			if (this.isSuperAdmin) {
				let hasAssetPoolingConnection = companyObj.permissions.pooling || UserUtil.hasAssetPoolingConnection(this.allConnectionsObj, companyId);
				this.userTypes = DropDownItemsUtil.retrieveUserTypeOptions(companyObj.permissions.transport, hasAssetPoolingConnection);
			} else {
				// If the selected company is connected company only of the current logged company,
				// Only "Scanner" and "Retailer" User Type can be created under this selection
				if (this.isConnectedCompany(companyId)) {
					this.userTypes = [
						{ value: null, text: ' - Please select - ' },
						{ value: 'Scanner', text: 'Scanner' },
						{ value: 'Retailer', text: 'Retailer' },
					];
				} else {
					let hasTransportPermission = companyObj ? companyObj.permissions.transport : false;
					let hasAssetPoolingPermission = companyObj ? companyObj.permissions.pooling : false;
					this.userTypes = DropDownItemsUtil.retrieveUserTypeOptions(hasTransportPermission, hasAssetPoolingPermission);
				}
			}

			this.form.type = null;
		},
		'form.type': function () {
			if (this.isAccessTypeDriver() && _.isEmpty(this.form.driversLicense)) {
				this.form.driversLicense = {
					licenseNo: '',
					image: {},
				};
			}
		},
	},
	async mounted() {
		this.userTypes = DropDownItemsUtil.retrieveUserTypeOptions(this.hasTransportPermission, this.hasAssetPoolingPermission);
		await this.getCurrentLocation();
	},
	methods: {
		isAccessTypeDriver() {
			return this.form.type == 'Driver';
		},
		async getCurrentLocation() {
			try {
				this.currLocation = await this.$getCurrentLocation();
			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			}
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// Show loading indicator
			this.isLoading = true;

			this.form = {
				...this.form,
				company: this.selCompany.name,
				companyId: this.selCompany.id
			};

			let companyObj = this.isSuperAdmin ? this.allCompaniesObj[this.selCompany.id] : this.allConnectedCompaniesObj[this.selCompany.id];

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				this.isLoading = false;
				return;
			} else if (UserUtil.exceedMaximum(companyObj, this.allUsersObj)) {
				this.$toaster.warning('You have exceeded the allowed number of active users for this company.');
				this.isLoading = false;
				return;
			} else if (!UserUtil.allowManagerAccount(this.form, this.allUsersObj)) {
				this.$toaster.warning('You can only have 1 manager account per company.');
				this.isLoading = false;
				return;
			} else if (!UserUtil.exceedMaxFreeAccount(this.form, this.allUsersObj, this.isBasicSubscription(companyObj))) {
				let warningMsg = this.isSuperAdmin ? 'You can only have 3 free accounts for company without subscription' : 'You can only have 3 free accounts for your clients.';
				this.$toaster.warning(warningMsg);
				this.isLoading = false;
				return;
			} else if (this.selCompany.isActive === "false") {
				this.$toaster.warning('You cannot add users to an inactive company.');
				this.isLoading = false;
				return;
			} else if (this.form.password !== this.form.confirmPassword) {
				this.$toaster.warning('Password and Confirm Password must be the same.');
				this.isLoading = false;
				return;
			}

			if (this.isAccessTypeDriver()) {
				if (_.isEmpty(this.form.driversLicense.image) && _.isEmpty(this.licenseMediaObj)) {
					this.$toaster.warning('Driver\'s License ID is required.');
					this.isLoading = false;
					return;
				} else if (UserUtil.hasExistingLicenseNo(this.allUsersObj, this.form)) {
					this.$toaster.warning(`License no. ${this.form.driversLicense.licenseNo} already exists.`);
					this.isLoading = false;
					return;
				}
			}

			const { data } = await userApi.validatePassword(this.form.password, this.loggedUser.id);
			if (!data.isSuccess) {
				this.$toaster.warning(data.message);
				this.isLoading = false;
				return;
			}

			await this.handleSubmit();
		},

		getUserObject(param) {
			return {
				firstName: param.firstName,
				middleName: param.middleName,
				lastName: param.lastName,
				company: param.company,
				companyId: param.companyId,
				position: param.position,
				employeeNo: param.employeeNo,
				type: param.type,
				emailAddress: param.emailAddress,
				password: param.password,
				contactNo: param.contactNo,
				isActive: param.isActive,
				isNewUser: true,
				isPasswordExpired: false,
				passwordExpirationDate: 0,
				hasNotif: param.hasNotif,
				has2WayAuth: param.has2WayAuth,
				hasGeofencing: param.hasGeofencing,
				companyAccess: param.companyAccess,
				driversLicense: param.driversLicense,
				dateCreated: DateUtil.getCurrentTimestamp(),
				createdBy: this.loggedUser.id,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				updatedBy: this.loggedUser.id,
			};
		},
		async handleSubmit() {
			try {
				// show loading indicator
				this.isLoading = true;

				this.processFormValues();
				
				let userObj = this.getUserObject(this.form);
				let { data } = await userApi.addUser(
					userObj,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {
					// upload drivers license
					if (!_.isEmpty(this.licenseMediaObj)) {
						data.user = await this.uploadDriversLicense(data.user);
					} else {
						data.user.driversLicense = {
							licenseNo: '',
							image: {}
						};
					}

					// Update company setup status
					await companyDAO.updateCompanySetupStatus({
						companyId: this.selCompany.id,
						data: {
							hasActiveUser: true,
						},
					});

					this.$toaster.success(`User "${this.name}" was created successfully.`);
					EventBus.$emit('onCloseSaveUser', data.user);
					this.$refs.modal.hide();

				} else {
					let error = data.errors[0];
					if (!_.isEmpty(error.code) &&
						error.code === 'auth/email-already-exists' ||
						error.code === 'auth/phone-number-already-exists') {
						this.$toaster.error(error.message);
					} else {
						this.$toaster.error(error);
					}
				}
			} catch (error) {
				this.$toaster.error(`Error creating user "${this.name}". Please try again.`);
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
		processFormValues() {
			// Removes excess whitespace
			this.form.firstName = ValidationUtil.removeExcessWhiteSpace(
				this.form.firstName
			);
			this.form.lastName = ValidationUtil.removeExcessWhiteSpace(
				this.form.lastName
			);
			this.form.middleName = ValidationUtil.removeExcessWhiteSpace(
				this.form.middleName
			);
			this.form.position = ValidationUtil.removeExcessWhiteSpace(
				this.form.position
			);

			// Assign the selected company to the user
			this.form.company = this.selCompany.name;
			this.form.companyId = this.selCompany.id;

			// Add default company access
			if (this.isSuperAdmin) {
				this.form.companyAccess = UserUtil.getDefaultCompanyAccess(this.form, this.allCompaniesObj);
			} else {
				this.form.companyAccess = UserUtil.getDefaultCompanyAccess(this.form, this.allConnectedCompaniesObj);
			}
		},


		/******************************* IMAGE HANDLING *********************************/
		async uploadDriversLicense(user) {
			if (this.licenseMediaObj && this.licenseMediaObj.isNew) {
				user = this.updateDriversLicense(user);

				const uploadResult = await this.firebaseUploadImage(this.licenseMediaObj, user);
				if (uploadResult.name && uploadResult.url) {
					user.driversLicense.image.name = uploadResult.name;
					user.driversLicense.image.url = uploadResult.url;

					try {
						let { data } = await userApi.updateUser(
							user,
							this.loggedUser.id,
							DateUtil.getCurrentTimestamp()
						);

						if (data.isSuccess) {
							user = data.user;
							this.onUploadingInProgress('success');
						}
					} catch (error) {
						this.$toaster.error(`Error uploading driver's license of user "${this.name}". Please try again.`);
					}
				}
			}

			return user;
		},
		updateDriversLicense(user) {
			if (!_.isEmpty(this.licenseMediaObj) && this.licenseMediaObj.url) {
				let currTimeStamp = DateUtil.getCurrentTimestamp();
				this.licenseMediaObj.name = `DL_${this.form.firstName}_${this.form.lastName}_${currTimeStamp}.png`;

				this.licenseMediaObj.geoaddress = {
					latitude: this.currLocation ? this.currLocation.lat : 0,
					longitude: this.currLocation ? this.currLocation.lng : 0
				};

				user.driversLicense.image = ImageUtil.createMedia(this.licenseMediaObj);
			}
			return user;
		},
		async firebaseUploadImage(mediaFile, user) {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			let filename = `DL_${user.firstName}_${user.lastName}_${currTimeStamp}.png`;

			this.onUploadingInProgress('uploading', filename, 0);

			return new Promise((resolve, reject) => {
				let storageRef = storage.ref(`${mediaFile.fbStoragePath}/${filename}`);
				let task = storageRef.put(mediaFile.file);

				task.on(
					'state_changed',
					(snapshot) => {
						let percentage =
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						this.onUploadingInProgress('uploading', filename, percentage);
					},
					(error) => {
						reject(error);
						this.onResetUploadingState();
					},
					() => {
						task.snapshot.ref.getDownloadURL().then((downloadURL) => {
							resolve({
								name: filename,
								url: downloadURL,
							});
						});
					}
				);
			});
		},
		onUploadingInProgress(status, name = '', percentage = 0) {
			this.uploadingState.name = name;
			this.uploadingState.percentage = percentage;
			this.uploadingState.status = status;
		},
		onResetUploadingState() {
			this.uploadingState.name = '';
			this.uploadingState.percentage = 0;
			this.uploadingState.status = '';
		},
		onSelectLicensePhoto(evt) {
			const vm = this;
			const file = evt.target.files[0];

			if (!FileUtil.isValidImgFileType(file)) {
				this.$toaster.error(
					'Invalid File Type: Please import a valid license in PNG or JPEG format.'
				);
				this.licenseMediaObj = {};
				return;
			}

			const url = URL.createObjectURL(file);
			let dimensions = { w: 0, h: 0 };

			const image = new Image();
			image.onload = function () {
				dimensions.w = image.width;
				dimensions.h = image.height;

				if (dimensions.w > 1000 || dimensions.h > 1000) {
					vm.$toaster.warning("Driver's License's width and height shouldn't be greater than 1000 pixels");
				} else {
					vm.licenseMediaObj = {
						url: url,
						file: file,
						fbStoragePath: 'images/driversLicenses',
						isNew: true,
					};
				}
			};
			image.src = url;
		},
		onRemoveLicensePhoto() {
			this.licenseMediaObj = {};
			this.$refs[`dirvers-lincense-file-input`].reset();
		},

		async onReset() {
			/* Reset our form values */
			this.form = { ...UserUtil.getDefaultUserObj() };

			// init company
			this.selCompany = { ...config.companyDefaultValue };

			this.licenseMediaObj = {};
			this.onResetUploadingState();

			this.showPassword = false;
			this.showConfirmPassword = false;

			// reset validation
			this.isLoading = false;
			this.$validator.reset();
			this.errors.clear();
		},

		// UTILS
		isBasicSubscription(companyObj) {
			let hasAssetPoolingPermission = companyObj.permissions.pooling;
			return !hasAssetPoolingPermission;
		},
		isConnectedCompany(companyId) {
			let companiesObj;
			if (this.isSuperAdmin) {
				companiesObj =  {...this.allCompaniesObj };
			} else {
				companiesObj = _.keyBy(_.filter(this.allCompaniesObj, o => {
					return o.id === this.loggedUserCompany.id || o.parentCompanyId === this.loggedUserCompany.id;
				}), 'id');
			}
			return !companiesObj[companyId];
		}
	},
};
</script>